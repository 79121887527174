<template>
  <el-dialog class="add-product-container" :title="popupsTitle" :visible="showState" width="1000px" top="2vh" @close="hidePopups()">
    <div class="select-product-container">
      <div class="flex select-product-header">
        <span class="select-product-label">请选择加购产品</span>
        <el-input placeholder="请输入关键字搜索" v-model="keywords" class="input-with-select">
          <el-select v-model="productType" slot="prepend" placeholder="请选择" @change="mirProductType">
            <el-option :label="item.categoryName" :value="item.categoryId" v-for="(item, i) in productTypeList" :key="i"></el-option>
          </el-select>
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
      <el-scrollbar class="el-scroll">
        <div class="select-product-content" v-loading="boxLoading">
          <ul class="flex content-header">
            <li class="header-item">产品封面</li>
            <li class="header-item" style="min-width: 260px">基础产品名称</li>
            <li class="header-item">规格</li>
            <li class="header-item">单价</li>
            <li class="header-item">计费单位</li>
            <li class="header-item"></li>
          </ul>
          <ul class="flex content-list" v-for="(item, i) in productList" :key="i">
            <li class="list-item">
              <img class="cover-img" :src="item.coverPictureUrl" alt="" />
            </li>
            <li class="list-item" style="min-width: 260px">{{ item.productName }}</li>
            <li class="list-item">{{ item.specification }}</li>
            <li class="list-item">￥{{ item.salePrice }}</li>
            <li class="list-item">{{ item.priceUnit }}</li>
            <li class="list-item">
              <el-checkbox v-model="item.checked" @change="mirChecked($event, item)"></el-checkbox>
            </li>
          </ul>
        </div>
      </el-scrollbar>
    </div>
    <div class="select-product-container" v-if="selectProducts.length > 0">
      <div class="flex select-product-header">
        <span class="select-product-label">已选产品</span>
      </div>
      <el-scrollbar class="el-scroll">
        <div class="select-product-content">
          <ul class="flex content-header">
            <li class="header-item">产品封面</li>
            <li class="header-item" style="min-width: 260px">基础产品名称</li>
            <li class="header-item">规格</li>
            <li class="header-item">单价</li>
            <li class="header-item">计费单位</li>
            <li class="header-item" style="min-width: 160px"></li>
          </ul>
          <ul class="flex content-list" v-for="(item, i) in selectProducts" :key="i">
            <li class="list-item">
              <el-image class="cover-img" :src="item.coverPictureUrl" alt="">
                <span class="el-image-error" slot="error">暂无图片</span>
              </el-image>
            </li>
            <li class="list-item" style="min-width: 260px">{{ item.productName }}</li>
            <li class="list-item">{{ item.specification }}</li>
            <li class="list-item">￥{{ item.salePrice }}</li>
            <li class="list-item">{{ item.priceUnit }}</li>
            <li class="list-item" style="min-width: 160px">
              <el-input-number v-model="item.number" size="mini" @change="mirNumber($event, item, i)"></el-input-number>
            </li>
          </ul>
        </div>
      </el-scrollbar>
      <ul class="content-total">
        <li class="total-item">
          <span class="total-item-label">小计：</span>
          <span class="total-item-text">{{ selectProductTotal }}</span>
        </li>
      </ul>
    </div>
    <div slot="footer" class="dialog-footer">
      <span>
        应付：
        <span style="color: #e86247">￥{{ selectProductTotal }}</span>
      </span>
      <span style="margin: 0 24px">
        合计：
        <span style="color: #e86247">￥{{ selectProductTotal }}</span>
      </span>
      <el-button @click="hidePopups">取消</el-button>
      <el-button type="primary" @click="submitForm()" :loading="submitLoadingStatus">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { getAddProductList, getAddProductTypeList, addProduct } from '@/api/order';
export default {
  props: {
    popupsTitle: {
      type: String,
      default: '',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => ({}),
    },
    currenTab: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      boxLoading: false,
      submitLoadingStatus: false,
      productTypeList: [],
      productType: undefined,
      keywords: undefined,

      productList: [],
      selectProducts: [],
      selectProductTotal: 0,
    };
  },
  watch: {
    showState(val) {
      if (val) {
        this.getData();
      }
    },
  },
  methods: {
    async getData() {
      try {
        this.boxLoading = true;
        let resProductType = (await getAddProductTypeList({ data: { categoryType: 1 } })).data || [];
        resProductType.unshift({ categoryName: '全部', categoryId: undefined });
        this.productTypeList = resProductType;

        this.getAddProductList();
        this.boxLoading = false;
      } catch (error) {
        // console.log(error);
        this.boxLoading = false;
      }
    },
    async getAddProductList() {
      let data =
        (
          await getAddProductList({
            data: {
              productIdOrName: this.keywords,
              productType: 1,
              selectProductId: this.currentData.orderProduct.productId,
              categoryId: this.productType,
            },
          })
        ).data.content || [];
      this.productList = data.map((item) => {
        item.checked = false;
        item.number = 1;
        return item;
      });
    },

    mirProductType() {
      this.getAddProductList();
    },
    mirChecked(value, item) {
      item.checked = value;
      if (!item.number) {
        item.number = 1;
      }
      this.selectProducts = this.productList.filter((item) => item.checked);
      this.calculateTotal();
    },
    async mirNumber(value, item, index) {
      new Promise(async (resolve, reject) => {
        if (value === 0) {
          item.checked = false;
          this.selectProducts = await this.selectProducts.filter((itemF) => itemF.checked);
        } else if (value >= 100) {
          this.$nextTick(() => {
            this.$set(this.selectProducts[index], 'number', 99);
          });
        }
        resolve();
      }).then(() => {
        this.calculateTotal();
      });
    },
    calculateTotal(currentNum, item) {
      let totalSumAll = 0;
      this.selectProducts.map((item) => (totalSumAll += Number(item.salePrice) * item.number));
      this.selectProductTotal = totalSumAll.toFixed(2);
    },
    submitForm() {
      this.submitLoadingStatus = true;
      let formData = {
        serviceId: this.currentData.serviceId,
        amount: this.selectProductTotal,
        amount: this.selectProductTotal,
        payAmount: this.selectProductTotal,
        productList: this.selectProducts.map((ma) => {
          let obj = {
            productId: ma.productId,
            num: ma.number,
            amount: ma.salePrice,
            payAmount: ma.salePrice,
          };
          return obj;
        }),
      };
      addProduct({ data: formData })
        .then((res) => {
          this.$notify.success({ title: '成功', message: '加购成功', duration: 2000 });
          if (this.currentData.inType === 'list') {
            this.$parent.getTableList();
          } else {
            if (this.currenTab.name == '加购信息') {
              if (this.currentData.inType === 'detailComponents') {
                this.$parent.$parent.getDetailAddOrder();
              } else {
                this.$parent.getDetailAddOrder();
              }
            }
          }
          this.hidePopups();
          this.submitLoadingStatus = false;
        })
        .catch((err) => {
          this.submitLoadingStatus = false;
        });
    },
    hidePopups() {
      this.$emit('hidePopups');
      this.selectProducts = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.add-product-container {
  ::v-deep .el-dialog__title {
    font-size: 16px;
  }
  ::v-deep .el-dialog__body {
    padding: 22px;
  }
  ::v-deep .el-dialog__footer {
    padding: 12px 24px;
    border-top: 1px solid #ededed;
  }
  ::v-deep .dialog-footer {
    text-align: right;
    font-size: 14px;
  }

  ::v-deep .el-input-group__prepend div.el-select .el-input__inner {
    width: 100px;
  }
  .select-product-container {
    margin-bottom: 24px;
    .select-product-header {
      justify-content: space-between;
      align-items: center;
      .select-product-label {
        font-weight: 500;
        color: #000000;
        font-size: 14px;
      }
      .input-with-select {
        width: 300px;
      }
    }
    .el-scroll {
      margin-top: 10px;
      padding-bottom: 16px;
      border: 1px solid #e0e0e0;

      ::v-deep .el-scrollbar-wrap {
        overflow-x: hidden;
      }
    }
    .select-product-content {
      max-height: 306px;
      .content-header {
        padding: 18px;
        .header-item {
          flex: 1;
          padding: 0 12px;
          max-width: 200px;
        }
      }
      .content-list {
        align-items: center;
        padding: 6px 18px;
        .list-item {
          flex: 1;
          padding: 4px 12px;
          max-width: 200px;
          overflow: hidden;
          .cover-img {
            width: 48px;
            height: 48px;
          }
        }
      }
    }
    .content-total {
      .total-item {
        flex: 1;
        border: 1px solid #e0e0e0;
        border-top: none;
        padding: 12px 24px;
        box-sizing: border-box;
        text-align: right;
        font-size: 14px;
        .total-item-label {
        }
        .total-item-text {
          color: #e86247;
        }
      }
    }
  }
  .select-product-container:last-child {
    margin: 0;
  }
}
</style>
