<template>
  <el-dialog class="upload-photo-container" :title="popupsTitle" :visible="showState" width="600px" top="30vh" @close="hidePopups()">
    <el-form ref="form" :model="form" :rules="rules" label-width="80px">
      <el-form-item label="客户照片" prop="pics">
        <UploadFile title="客户照片" uploadFileType="img" :fileList.sync="form.pics" />
      </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="hidePopups">取消</el-button>
      <el-button type="primary" @click="submitForm()" :loading="submitLoadingStatus">确认</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { uploadImg } from '@/api/order';
export default {
  props: {
    popupsTitle: {
      type: String,
      default: '',
    },
    showState: {
      type: Boolean,
      default: false,
    },
    currentData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      submitLoadingStatus: false,
      form: {
        pics: [],
      },
      rules: { pics: [{ type: 'array', required: true, message: '请上传客户照片', trigger: 'blur' }] },
    };
  },
  watch: {
    showState(val) {
      if (val) {
      }
    },
    'form.pics': {
      deep: true,
      handler(value) {
        this.$refs.form.clearValidate('pics');
      },
    },
  },
  methods: {
    submitForm() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submitLoadingStatus = true;
          let formData = JSON.parse(JSON.stringify(this.form));
          formData['serviceId'] = this.currentData.serviceId;
          formData.pics = formData.pics.map((item) => {
            item.url = item.http;
            item['PhotoName'] = '';
            return item;
          });

          uploadImg({ data: formData })
            .then((res) => {
              this.$notify.success({ title: '成功', message: '添加成功', duration: 2000 });
              if (this.currentData.inType === 'list') {
                this.$parent.getTableList();
              } else {
                this.$parent.getDetailPhotos();
              }
              this.hidePopups();
              this.submitLoadingStatus = false;
            })
            .catch((err) => {
              this.submitLoadingStatus = false;
            });
        }
      });
    },
    hidePopups() {
      this.$emit('hidePopups');
      this.$refs.form.resetFields();
      Object.assign(this.$data.form, this.$options.data().form);
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-order-container {
  ::v-deep .el-dialog__title {
    font-size: 16px;
  }
  ::v-deep .el-dialog__body {
    padding: 24px 40px 16px 40px;
  }
  ::v-deep .el-dialog__footer {
    padding: 12px 24px;
    border-top: 1px solid #ededed;
  }
  ::v-deep .dialog-footer {
    text-align: right;
  }
}
</style>
